<template>
  <div class="clients">
    <h1 class="text-center">Клиент <b class="grey--text">№{{ client.data.id }}</b></h1>
    <h1 class="text-center">
      {{ client.data.name === 'Noname' ? '' : client.data.name }}
      {{ client.data.surname }}
      {{ client.data.second_name }}
    </h1>
    <div class="text-center my-5">
      <v-chip
          v-if="client.data.is_vip"
          color="amber ms-2 white--text subtitle-2"
      >
        <v-icon  class="display-1 white--text">mdi-crown</v-icon> VIP
      </v-chip>
    </div>
      <v-list two-line>
        <v-list-item v-if="client.data.phone">


          <v-list-item-content>
            <v-list-item-title>{{ client.data.phone }}</v-list-item-title>
            <v-list-item-subtitle>Телефон</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon><v-btn fab color="indigo" :href="'tel:' + client.data.phone">
            <v-icon color="white">
              mdi-phone
            </v-icon></v-btn>
          </v-list-item-icon>
          <v-list-item-icon>
            <v-btn fab color="green" :href="'https://api.whatsapp.com/send?phone=' + client.data.phone"><v-icon color="white">mdi-whatsapp</v-icon></v-btn>

          </v-list-item-icon>
        </v-list-item>
      </v-list>

    <v-tabs
        v-model="tab"
        grow
        background-color="brown lighten-5"
        icons-and-text
    >
      <v-tab href="#1" class="text-subtitle-2" key="1">
        Скидка
        <v-icon>mdi-sale</v-icon>
      </v-tab>
      <v-tab-item value="1">
        <v-list-item >
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-sale
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ client.data._discount }}%</v-list-item-title>
            <v-list-item-subtitle>скидка клиента</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item >
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-currency-usd
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ client.data.amount }} руб.</v-list-item-title>
            <v-list-item-subtitle>общее накопление</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-tab-item>

      <v-tab href="#2" class="text-subtitle-2" key="2">
        О клиенте
        <v-icon>mdi-card-account-details-outline</v-icon>
      </v-tab>
      <v-tab-item value="2">
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-calendar-range
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-if="client.data.birthday == '2000-01-01'">{{ nodata }}</v-list-item-title>
            <v-list-item-title v-else>{{ client.data.birthday | formatDate }}</v-list-item-title>
            <v-list-item-subtitle>дата рождения</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-map-marker
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <p>{{ client.data.address_delivery || nodata }}</p>
            <v-list-item-subtitle>адрес доставки</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-card-account-details-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <p>{{ client.data.note || nodata }}</p>
            <v-list-item-subtitle>Дополнительная информация</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-folder-plus-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ client.data.create_at | formatDate }}</v-list-item-title>
            <v-list-item-subtitle>добавлен в систему</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>
      </v-tab-item>

      <v-tab href="#3" class="text-subtitle-2" key="3">
        Покупки
        <v-icon>mdi-cart-check</v-icon>
      </v-tab>
      <v-tab-item value="3">
        <v-row class="my-5">

          <v-col cols="12" class="text-center" v-if="!client.data.bill">Список покупок пуст</v-col>
          <v-col cols="12" class="text-center" v-else>
            <v-row>
              <v-expansion-panels>
                <v-expansion-panel
                    v-for="(bill, i) in client.data.bill"
                    :key="i"
                >
                  <v-expansion-panel-header text="blue">
                    {{ i+1 }}. Чек от {{ bill.created_at }} на сумму {{ bill.total_cost }} руб.</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">
                            товар
                          </th>
                          <th class="text-left">
                            цена
                          </th>
                          <th class="text-left">
                            шт.
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(good, i) in bill.goods"
                            :key="i"
                        >
                          <td class="text-left">{{ good.name }}</td>
                          <td class="text-left">{{ good.price }}</td>
                          <td class="text-left">{{ good.quantity }}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-divider></v-divider>
                    <p class="mt-5">Итого с учетом скидки: <strong>{{ bill.total_cost }}</strong> руб.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-center">
              <p>Общее накопление <strong>{{ client.data.amount }}</strong> руб.</p>
            <v-btn color="green" x-large outlined :to=linkAdd>+ Добавить покупку</v-btn>
          </v-col>
        </v-row>
      </v-tab-item>


    </v-tabs>

    <AppButtonEdit iconButton="mdi-account-edit-outline" :tilesButton="tiles" />
  </div>
</template>

<script>
import HTTP from "../api";
import moment from 'moment';
import AppButtonEdit from "../components/AppButtonEdit";

export default {
  components: {
    AppButtonEdit
  },
  data() {
    return {
      nodata: 'Нет данных',
      linkAdd: `${this.$route.params.id}/create_bill`,
      client: {},
      tab: "1",
      tiles: [
        { icon: 'mdi-account-edit', title: 'Изменить данные клиента', linkTo: `${this.$route.params.id}/edit` },
        { icon: 'mdi-cart-plus', title: 'Давить покупку', linkTo: `${this.$route.params.id}/create_bill` },
      ],
    };
  },
  mounted() {
    HTTP
        .get(`clients/${this.$route.params.id}`)
        .then(response => (
            this.client = response,
            console.log(this.client)

        ));
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
      }
    }
  },
}
</script>
