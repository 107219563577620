<template>
  <div class="text-center button-add">
    <v-bottom-sheet v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="green"
            dark
            v-bind="attrs"
            v-on="on"
            large
            fab
            direction="direction"
            right
        >
          <v-icon>{{ iconButton }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-subheader>Редактировать</v-subheader>
        <v-list-item
            v-for="tile in tilesButton"
            :key="tile.title"
            :to="tile.linkTo"

        >
          <v-list-item-avatar>
            <v-avatar
                size="32px"
                tile
            >
              <v-icon color="green">{{ tile.icon }}</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>{{ tile.title }}</v-list-item-title>

        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: "AppButtonAdd",
  props: ['tilesButton', 'iconButton']
}
</script>

<style scoped>
.button-add {
  position: fixed;
  bottom: 25px;
  right: 20px;
  z-index: 1000;
}
</style>